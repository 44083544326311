@import "./variables";

.horizontalTimeline{
  .buttons{
    display: none;
  }
  .dot-label{
    span{
      background-color: $linkBg;
    }
  }
}
