$primary: #021947;
$nala-primary: #007aff;
// text color
$infoColor: #1f24bf;
$successColor: #00711e;
$warningColor: #8a6500;
$errorColor: #850013;
// background
$infoBg: #ccebff;
$successBg: #a7ffd9;
$warningBg: #ffffca;
$errorBg: #ffc7c1;
$linkBg: #2930FF;

$lightpurpleBg: #d2d3f8;
$grey: #bdbdbd;
